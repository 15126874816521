/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useAiryLayoutPortfolio = () => {
  const query = useStaticQuery(graphql`
    query airyLayoutPortfolio {
      poster1: file(relativePath: { eq: "airy-layout/portfolio/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(relativePath: { eq: "airy-layout/portfolio/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(relativePath: { eq: "airy-layout/portfolio/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(relativePath: { eq: "airy-layout/portfolio/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(relativePath: { eq: "airy-layout/portfolio/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(relativePath: { eq: "airy-layout/portfolio/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(relativePath: { eq: "airy-layout/portfolio/07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(relativePath: { eq: "airy-layout/portfolio/08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(relativePath: { eq: "airy-layout/portfolio/09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster10: file(relativePath: { eq: "airy-layout/portfolio/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster11: file(relativePath: { eq: "airy-layout/portfolio/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster12: file(relativePath: { eq: "airy-layout/portfolio/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Наталья Бельская",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Лидия Осипова",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Ольга Савенкова",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Мария Сим",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Никита Попов",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Александр Селиванов",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Анна Попович",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Ирина Кухтей",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53510",
      src: portfolio[8].childImageSharp.fluid,
      author: "Ольга Кузнецова",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53511",
      src: portfolio[9].childImageSharp.fluid,
      author: "Марина Прусова",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53512",
      src: portfolio[10].childImageSharp.fluid,
      author: "Максим Косточкин",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53513",
      src: portfolio[11].childImageSharp.fluid,
      author: "Светлана Аистова",
      course: "Графсистема Воздух",
    },
  ];

  return data;
};

export default useAiryLayoutPortfolio;
