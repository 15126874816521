/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "3536241",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "3536445",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "3536447",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "3536449",
  },
];

export default {
  AiryExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle:
        "Комбинация элементов и принципов по определённым правилам.",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/airy-layout/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема Воздух?",
      explanationsTextTitle: "Воздушные композиции (Airy Layout).",
      explanationsTextDescription:
        "Понимание минимализма. Если вы хотите осознать, почему полупустые макеты работают. Почему это вовсе не пустота, а осмысленное пространство. Чёрный и белый выступают как полноценные цвета. Настраивается тонкая ювелирность пропорций между элементами. Аскетичность и театральность. Всё это в графсистеме Воздуха.",
    },
  ],
  priceRange,
};
